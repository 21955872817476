import { serverUrl } from "@/global/variables";
import $ from "jquery";
import exportBtns from "./util/export-buttons"

// Setup Table
export const setupTable = () => {
    return $("#vue-datatable").DataTable({
        processing: true,
        serverSide: true,
        ajax: {
            url: serverUrl("/api/notify"),
            beforeSend: function (request) {
                request.setRequestHeader(
                    "Authorization",
                    `Bearer ${localStorage.getItem("token")}`
                );
            },
        },
        columns: [
            { data: "id" },
            { data: "title" },
            { data: "description" },
            { data: "type" },
            { data: "created_at" },
            { name: "actions", data: "actions" },
        ],
        buttons: [...exportBtns()],
        dom: "Bfrtip",
        pageLength: 25,
        order: [[1, "asc"]],
        columnDefs: [
            {
                targets: 0,
                orderable: false,
            },
            {
                targets: 1,
                orderable: false,
            },
            {
                targets: 2,
                orderable: false,
            },
            {
                render: function (data, type, row) {
                    const detailsBtn = row.type == 'NOTIFY' ? `<button type="button" onClick="window.detailsNotify(${row.id})" class="btn btn-icon rounded-circle btn-flat-success editBtn"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg></button>` : ''
                    const deleteBtn = `<button type="button" onClick="window.inactiveNotify(${row.id})" class="btn btn-icon rounded-circle btn-flat-danger editBtn"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-delete"><path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path><line x1="18" y1="9" x2="12" y2="15"></line><line x1="12" y1="9" x2="18" y2="15"></line></svg></button>`
                    return detailsBtn + deleteBtn;
                },
                targets: 5,
            },
        ]
    });
}

// Notification for users - to get all users have this notification
// Setup Table
export const setupTableToUsers = ({notifyID}) => {
    return $("#details-table").DataTable({
        processing: true,
        serverSide: true,
        ajax: {
            url: `/api/notify/${notifyID}/users-to`,
            beforeSend: function (request) {
                request.setRequestHeader(
                    "Authorization",
                    `Bearer ${localStorage.getItem("token")}`
                );
            },
        },
        columns: [
            { data: "id" },
            { data: "user.username" },
            { data: "user.email" },
            { data: "is_seen" },
            { data: "is_cleared" },
        ],
        buttons: [...exportBtns()],
        dom: "Bfrtip",
        pageLength: 10,
        order: [[1, "asc"]],
        columnDefs: [
            {
                targets: 0,
                orderable: false,
            },
            {
                targets: 1,
                orderable: false,
            },
            {
                targets: 2,
                orderable: false,
            },
            {
                targets: 3,
                orderable: false,
                render: (data,) => {
                    return data ? '<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>' : ''
                }
            },
            {
                targets: 4,
                orderable: false,
                render: (data,) => {
                    return data ? '<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>' : ''
                }
            },
        ]
    });
}