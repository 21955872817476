<template>
  <section id="notificationManage">
    <b-row>
      <b-col cols="12">
        <SendNotify @add="$refs.list.refresh()" />
      </b-col>
      <b-col cols="12">
        <ListNotify ref="list" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import SendNotify from "./SendNotify";
import ListNotify from "./list/ListNotify";
export default {
  data: () => ({}),
  components: {
    BRow,
    BCol,
    SendNotify,
    ListNotify,
  },
};
</script>

