<template>
  <div>
    <!-- Table View -->
    <VueDatatable
      title="All notifications"
      ref="myTable"
      :setupTable="setupTable"
      :hideFooter="true"
    >
      <template v-slot:thead>
        <tr>
          <th class="id-col" style="width: 59px">
            <span>ID</span>
          </th>
          <th style="width: 65px">Title</th>
          <th style="width: 200px">Description</th>
          <th>Type</th>
          <th>Created At</th>
          <th>Actions</th>
        </tr>
      </template>
    </VueDatatable>
    <DetailsNotifyModal />
  </div>
</template>

<script>
import VueDatatable from "@/components/util/vue-datatable/Index.vue";
import { setupTable } from "@/datatable/notifications.datatable.js";
import { BCard, BButton, BModal } from "bootstrap-vue";
import Notify from "@/services/notification.service.js";
import DetailsNotifyModal from "./DetailsNotify";
export default {
  data: () => ({
    dataTable: null,
    setupTable,
  }),
  methods: {
    refresh() {
      this.dataTable.refresh();
    },
    async deleteNotify(id) {
      if (!confirm("Are you sure do you want to delete?")) return;
      this.loading = true;
      this.items = await Notify.delete(id);
      this.refresh();
    },
  },
  async mounted() {
    this.dataTable = this.$refs.myTable || {};
    // Delete / InActive notify
    window.inactiveNotify = this.deleteNotify;
  },
  components: {
    BCard,
    BButton,
    BModal,
    VueDatatable,
    DetailsNotifyModal,
  },
};
</script>

<style>
</style>