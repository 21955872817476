<template>
  <b-card title="Send Notification" class="mb-2">
    <div class="notify-body">
      <b-form-group>
        <b-form-radio
          inline
          v-for="type in notifyTypes"
          :key="`typ${type.value}`"
          v-model="form.type"
          name="notify-types"
          :value="type.value"
        >
          {{ type.label }}
        </b-form-radio>
      </b-form-group>
      <b-form-input
        type="text"
        :placeholder="form.type === 'EMAIL' ? 'Subject' : 'Title'"
        v-model="form.title"
      />
      <b-form-textarea
        placeholder="Description"
        v-model="form.description"
        rows="3"
      />
      <b-form-checkbox
        v-model="forOnePerson"
        class="custom-control-primary"
        name="check-button"
        switch
        inline
      >
        Notify only one person
      </b-form-checkbox>
      <v-select
        v-if="forOnePerson"
        v-model="username"
        placeholder="Select Username"
        :options="users.map((u) => u.username)"
      />
    </div>
    <b-button @click="send" variant="primary" :disabled="loading">
      <b-spinner v-if="loading" small />
      Notify {{ forOnePerson ? username || "" : `All (${users.length})` }}
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BInputGroup,
  BFormGroup,
  BFormRadio,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Notify from "@/services/notification.service.js";
import User from "@/services/user.service.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  data: () => ({
    loading: false,
    form: {
      title: "",
      description: "",
      type: "NOTIFY",
    },
    notifyTypes: [
      {
        label: "Notify",
        value: "NOTIFY",
      },
      {
        label: "Email",
        value: "EMAIL",
      },
    ],
    users: [],
    username: "",
    forOnePerson: false,
  }),
  async mounted() {
    this.users = (await User.all("?length=1000000")).data;
  },
  methods: {
    async send() {
      this.loading = true;
      try {
        const newNotify = await Notify.send(
          this.forOnePerson
            ? { ...this.form, username: this.username }
            : this.form
        );
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "CheckIcon",
            text: `Notification has been sent ${this.username}`,
            variant: "success",
          },
        });
        this.$emit("add", newNotify);
        this.title = "";
        this.description = "";
      } catch (e) {
        console.log(e);
        const msg =
          e.response.status == 422
            ? Object.values(e.response.data.errors)[0][0]
            : "Something went wrong, please try again";
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Faild to add",
            icon: "XIcon",
            text: msg,
            variant: "danger",
          },
        });
      }
      this.loading = false;
    },
  },
  components: {
    BCard,
    BCardText,
    BButton,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BFormGroup,
    BFormRadio,
    ToastificationContent,
    vSelect,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.notify-body {
  > * {
    margin-bottom: 20px;
  }
}
</style>