<template>
  <!-- Details Modal -->
  <div>
    <b-modal
      ref="modal"
      id="modal-center"
      title="More Details"
      v-model="status"
      size="lg"
      ok-only
      centered
    >
      <!-- Table To Users -->
      <VueDatatable
        compID="details-table"
        title="All users"
        ref="detailsTable"
        :setupTable="setupTable"
        :hideFooter="true"
      >
        <template v-slot:thead>
          <tr>
            <th class="id-col" style="width: 59px">
              <span>ID</span>
            </th>
            <th style="width: 40px">Username</th>
            <th style="width: 60px">E-mail</th>
            <th>Seen</th>
            <th>Cleared</th>
          </tr>
        </template>
      </VueDatatable>
    </b-modal>
  </div>
</template>

<script>
import VueDatatable from "@/components/util/vue-datatable/Index.vue";
import { BModal } from "bootstrap-vue";
import { setupTableToUsers } from "@/datatable/notifications.datatable.js";
export default {
  data: () => ({
    dataTable: null,
    status: false,
    setupTable: setupTableToUsers,
  }),
  mounted() {
    window.detailsNotify = (id) => {
      this.status = true;
      this.$nextTick(() => {
        this.dataTable = this.$refs.detailsTable || {};
        this.dataTable.setup({ notifyID: id });
      });
    };
  },
  components: {
    BModal,
    VueDatatable,
  },
};
</script>

<style>
</style>